<template>
  <div class="row">
    <div class="col-md-12">
      <email-archive-datatable/>
    </div>
  </div>
</template>

<script>
import EmailArchiveDatatable from "@/pages/Email/datatables/EmailArchiveDatatable";

export default {
  name: "IndexEmailArchivePage",
  components: {EmailArchiveDatatable},
}
</script>

<style scoped>

</style>
