<template>
  <div>
    <octo-table
      show-filters
      :action="endpoints.DATATABLES.emailArchive"
      :fields="fields"
      :filters="filters"
      :name="name"
      :show-id="false"
      :order-by-items="['created_at']"
      @onResetFilters="filters = {}"
    >
      <template v-slot:receiver="data">
        <div class="text-truncate">
          {{ getReceiverFullName(data.row) }}
        </div>
      </template>

      <template v-slot:email="data">
        <div class="small">
          {{ data.row | optional('receiver') }}
        </div>
      </template>

      <template v-slot:template="data">
        <div class="small text-uppercase">{{ $t('emails.templates.' + emailTemplates[data.row.email_template_id].name) }}</div>
      </template>

      <template v-slot:sender="data">
        <div class="small text-uppercase">
          {{ users[data.row.user_id] | optional('lastname') }} {{ users[data.row.user_id] | optional('firstname') }}
        </div>
      </template>

      <template v-slot:date="data">
        {{ data.row.created_at | datetime }}
      </template>

      <template v-slot:status="data">
        <badge v-if="data.row.status" :type="data.row.status | emailStatusColor" class="text-uppercase">
          {{ $t('emails.status.' + data.row.status) }}
        </badge>
      </template>

      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">
          <el-tooltip v-if="data.row.emailArchivable" :content="$t('common.registry')" :open-delay="300" placement="top">
            <base-button class="mx-1" size="sm" icon link @click="$router.push({name: 'registries.show', params: {id :  data.row.emailArchivable.registry_id}})">
              <octo-icon icon="user"/>
            </base-button>
          </el-tooltip>
          <el-tooltip :content="$t('common.info')" :open-delay="300" placement="top">
            <base-button class="mx-1" size="sm" icon link @click="openModal(data.row)">
              <octo-icon icon="magnifying-glass"/>
            </base-button>
          </el-tooltip>
        </div>
      </template>
      <template slot="custom-filters">
        <div class="col-12 col-md-6">
          <base-input :label="$t('common.sender')">
            <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.userIds"
            >
              <el-option
                v-for="user in users"
                class="select-default text-uppercase"
                :value="user.id"
                :key="user.id"
                :label="`${$options.filters.optional(user, 'lastname')} ` +
                        `${$options.filters.optional(user, 'firstname')}`"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-md-6">
          <base-input :label="$t('common.template')">
            <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.templateIds"
            >
              <el-option
                v-for="template in emailTemplates"
                class="select-default text-uppercase"
                :value="template.id"
                :key="template.id"
                :label="$t('emails.templates.' + template.name)"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-md-6">
          <base-input :label="$t('callcenter.date_sent')">
            <el-date-picker
              v-model="filters.dateRange"
              type="daterange"
              align="right"
              unlink-panels
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              range-separator="-"
              :start-placeholder="$t('fields.date_start')"
              :end-placeholder="$t('fields.date_end')"
              :picker-options="pickerOptions"
            />
          </base-input>
        </div>
        <div class="col-12 col-md-6">
          <base-input :label="$t('datatable.type')">
            <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.targetTypes"
            >
              <el-option
                v-for="type in targetTypes"
                class="select-default text-uppercase"
                :value="type"
                :label="$t('emails.targets.' + type)"
                :key="type"
              />
            </el-select>
          </base-input>
        </div>
      </template>
    </octo-table>
    <modal centered modalClasses="modal-lg"  :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">{{ $t('common.details') }}</h5>
      <email-archive-details :email-archive="currentEmailArchive" :key="modalKey"/>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {DatePicker, Option, Select} from "element-ui";
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {endpoints} from "@/routes/endpoints";
import Modal from "@/components/Modal";
import EmailArchive from "@/models/emailArchive";
import EmailArchiveDetails from "@/pages/Email/components/EmailArchiveDetails";
import Badge from "@/components/Badge";

export default {
  name: "EmailArchiveDatatable",
  components: {
    Badge,
    EmailArchiveDetails,
    Modal,
    LabelThemeComponent,
    OctoIcon,
    OctoTable,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      endpoints: endpoints,
      name: "EmailArchiveDatatable",
      filters: {},
      showModal: false,
      modalKey: 0,
      currentEmailArchive: _.cloneDeep(EmailArchive),
      targetTypes: ['Lead', 'Deal', 'Student'],
      fields: [
        {prop: 'date', label: 'date', align: 'center', slot: true, width: 200, fixed: true},
        {prop: 'receiver', label: 'receiver', slot: true, width: 300, fixed: true},
        {prop: 'email', label: 'email', slot: true, width: 300,},
        {prop: 'sender', label: 'sender', slot: true, width: 200},
        {prop: 'template', label: 'template', slot: true, minWidth: 200},
        {prop: 'status', label: 'status', slot: true, width: 200},
        {prop: 'actions', label: 'actions', slot: true, align: 'center', fixed: 'right', width: 100},
      ],
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
      users: 'common/users',
      emailTemplates: 'common/emailTemplates',
    }),
  },
  methods: {
    pickerOptions() {
      return {firstDayOfWeek: 1};
    },

    openModal(emailArchive) {
      this.currentEmailArchive = this.$_.cloneDeep(emailArchive);
      this.modalKey++
      this.showModal = true;
    },

    getReceiverFullName(emailArchive) {
      let registry;

      switch (emailArchive.target_type) {
        case 'Deal':
        case 'Student':
        case 'Lead':
          registry = emailArchive?.emailArchivable?.registry || {};
          break;
        default:
          return '';
      }

      return registry?.surname + ' ' + registry?.name;
    },
  }
}
</script>

<style scoped>

</style>
